'use client'

import Link from '@dg/common/components/Link'
import {
	device
} from '@dg/common/lib/common'
import useImageValidate from '@dg/common/lib/hooks/useImageValidate'
import pathsData from '@dg/common/public/json/mobile/paths'
import pathsPcData from '@dg/common/public/json/pc/paths'
import Image from 'next/image'
import {
	useEffect
} from 'react'
import {
	useTranslation
} from 'react-i18next'

const GlobalError = ({
	error
}: {
	error: Error & {
		digest?: string;
	};
}) => {
	const {
		t
	} = useTranslation()

	const imageValidate = useImageValidate()

	useEffect(() => {
		const errorMutation = {
			callback: (mutationsList: MutationRecord[]) => {
				for (const mutation of mutationsList) {
					const obj = mutation.target as HTMLElement

					const header = obj.querySelector(`#wrap-header`) as HTMLElement
					const content = obj.querySelector(`#wrap-content`) as HTMLElement

					content.style.paddingBottom = ``
					content.style.minHeight = `${window.innerHeight - (header?.offsetHeight ?? 0)}px`
				}
			},
			config: {
				attributes: true
			},
			obj: document.querySelector(`body`) as HTMLElement ?? null
		}

		const errorMutationObserver = new MutationObserver(errorMutation.callback)

		if (errorMutation.obj !== null) {
			errorMutationObserver.disconnect()

			errorMutationObserver.observe(errorMutation.obj, errorMutation.config)
		}

		const cleanup = () => {
			if (errorMutation.obj !== null) {
				errorMutationObserver.disconnect()
			}
		}

		return cleanup
	}, [])

	return (
		<html
			lang="ja"
		>
			<body>
				<style>
					{
						`
							#wrap-content {
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: #fcfcfd;
							}
						`
					}
				</style>

				<div
					className="light bg-dg-white text-dg-black transition-bgColor"
					id="wrap"
				>
					<main
						className="px-5 pb-10 pt-5"
						id="wrap-content"
					>
						<section
							id="error"
						>
							<div
								className="flex size-full flex-col items-center justify-center"
							>
								<Image
									alt="Page Not Found"
									className="object-cover"
									height={51}
									onError={imageValidate.onError}
									src="/images/common/404.png"
									width={144}
								/>

								<strong
									className="mt-8 block text-lg transition-colors"
								>
									{t(`이 페이지에 액세스할 수 없습니다.`)}
								</strong>

								<span
									className="mb-6 mt-4 block text-sm text-dg-600 dark:text-dgdark-600"
								>
									{t(`불편을 끼쳐 드려 죄송합니다. 네트워크 상태를 확인한 후 다시 시도해 주세요.`)}
								</span>

								<Link
									className="border border-solid border-dg-500 dark:border-dgdark-500"
									href={(device().browser.mobile === true ? pathsData : pathsPcData).home}
									pd
								>
									{t(`이전 페이지로 돌아가기`)}
								</Link>

								<span
									className="mt-25 block text-sm text-dg-600 dark:text-dgdark-600"
								>
									{error.message}
								</span>
							</div>
						</section>
					</main>
				</div>
			</body>
		</html>
	)
}

export default GlobalError
